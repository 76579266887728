<template>
    <div class="flex-box vertical content md" v-if="info">
        <div class="detail-ctx">
            <div class="other-title">基本信息</div>
            <div class="detail-info-item">
                <div class="detail-info-label">日期</div>
                <div class="detail-info-value">{{info.create_time}}</div>
            </div>
            <div class="detail-info-item">
                <div class="detail-info-label">用户昵称</div>
                <div class="detail-info-value">{{info.username}}</div>
            </div>
            <div class="detail-info-item">
                <div class="detail-info-label">内容</div>
                <div class="detail-info-value">{{info.content}}</div>
            </div>
            <div class="detail-info-item">
                <div class="detail-info-label">状态</div>
                <div class="detail-info-value">{{info.statusText}}</div>
            </div>
            <template v-if="info.reply">
                <div class="other-title">处理情况</div>
                <div class="detail-info-item">
                    <div class="detail-info-label">处理日期</div>
                    <div class="detail-info-value">{{info.update_time}}</div>
                </div>
                <div class="detail-info-item">
                    <div class="detail-info-label">回复</div>
                    <div class="detail-info-value">{{info.reply || '无'}}</div>
                </div>
            </template>
        </div>
        <a-space class="detail-footer">
            <a-button type="primary" @click="showReply" v-if="!info.reply">确认处理</a-button>
        </a-space>
        <a-modal
            title="回复"
            :maskClosable="false"
            :width="480"
            @ok="confirmReply"
            v-model="visible">
            <a-textarea v-model="content" placeholder="请输入" :rows="4" />
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: "ComplaintDetail",
        data() {
            return {
                info: null,
                visible: false,
                content: null
            }
        },
        created() {
            this.getDetail();
        },
        methods: {
            confirmReply() {
                let content = this.content;
                if(content) {
                    let info = this.info;
                    this.$axios({
                        url: `/admin/complaint/${info.id}`,
                        method: 'PATCH',
                        data: {
                            reply: content
                        }
                    }).then(res => {
                        this.$message.success("已处理");
                        this.$set(info, 'reply', res.reply);
                        this.$set(info, 'statusText', '已处理');
                        this.visible = false;
                    })
                } else {
                    this.$message.warning("请输入回复内容");
                }
            },
            getDetail() {
                let id = this.$route.params.id;
                let url = `/admin/complaint/${id}`;
                this.$axios(url).then(res => {
                    res.username = res.miniUser && res.miniUser.uname ? res.miniUser.uname : '微信用户';
                    res.statusText = res.reply ? '已处理' : '未处理';
                    this.info = res;
                })
            },
            showReply() {
                let info = this.info;
                if(info.rely) return;
                this.visible = true;
            }
        }
    }
</script>

<style scoped lang="less">
    .detail-ctx {
        flex: 1;
        overflow: auto;
        padding: 10px 30px;
    }
</style>
